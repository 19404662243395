import React from 'react'

import { Helmet } from 'react-helmet'

import './global.css'
import Wrapper from './src/components/Wrapper'

// wrapRootElement is part of the Gatsby API, and thus cannot be the default export
/* eslint-disable import/prefer-default-export, react/jsx-filename-extension */
export const wrapRootElement = ({ element }) => (
  <Wrapper>
    <>
      <Helmet>
        <meta
          name="google-site-verification"
          content="hFgn8qNeubmaIXpXszIuJd5z-np2VqV9bL2hMqZmpm4"
        />
      </Helmet>
      {element}
    </>
  </Wrapper>
)
